import { takeEvery } from 'redux-saga/effects';
import * as profileActions from '../actions/Profile';
import api from '../utils/api';
import { apiRequest } from './index';
import { Slide, toast } from 'react-toastify';

const getProfile = apiRequest.bind(null, profileActions.getProfile, api.getProfile);
const updateProfile = apiRequest.bind(null, profileActions.updateProfile, api.updateProfile);
const uploadProfilePhoto = apiRequest.bind(null, profileActions.uploadProfilePhoto, api.uploadUserImage);
const updatePassword = apiRequest.bind(null, profileActions.updateProfilePassword, api.updateUserPassword);
const updatePasswordSelf = apiRequest.bind(null, profileActions.updatePasswordSelf, api.updateUserPasswordSelf);

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

function* handleChangeProfile(action) {
  const arg = {
    id: action.payload.id,
  };
  yield apiRequest.apply(null, [profileActions.getProfile, api.getProfile, arg]);

  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: true,
    closeOnClick: true,
    position: 'bottom-center',
    type: 'success',
  });
}

function* showSuccessMessage(action) {
  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: true,
    closeOnClick: true,
    position: 'bottom-center',
    type: 'success',
  });
}

export function* watchGetProfile() {
  yield takeEvery(profileActions.GET_PROFILE.REQUEST, getProfile);
}

export function* watchUpdateProfile() {
  yield takeEvery(profileActions.UPDATE_PROFILE.REQUEST, updateProfile);
}

export function* watchUploadProfilePhotoRequest() {
  yield takeEvery(profileActions.UPLOAD_PROFILE_PHOTO.REQUEST, uploadProfilePhoto);
}

export function* watchUploadProfilePhotoSuccess() {
  yield takeEvery(profileActions.UPLOAD_PROFILE_PHOTO.SUCCESS, handleChangeProfile);
}

export function* watchUpdatePasswordRequest() {
  yield takeEvery(profileActions.UPDATE_PROFILE_PASSWORD.REQUEST, updatePassword);
}

export function* watchUpdatePasswordSuccess() {
  yield takeEvery(profileActions.UPDATE_PROFILE_PASSWORD.SUCCESS, showSuccessMessage);
}

export function* watchUpdatePasswordSelfRequest() {
  yield takeEvery(profileActions.UPDATE_PASSWORD_SELF.REQUEST, updatePasswordSelf);
}

export function* watchUpdatePasswordSelfSuccess() {
  yield takeEvery(profileActions.UPDATE_PASSWORD_SELF.SUCCESS, showSuccessMessage);
}

export function* watchUpdateProfileSuccess() {
  yield takeEvery(profileActions.UPDATE_PROFILE.SUCCESS, showSuccessMessage);
}
