import * as ActionTypes from "../actions/Profile";
import * as AuthActionTypes from "../actions/Auth";

const initialState = {
  profile: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGOUT.SUCCESS:
      return { ...initialState };
    case ActionTypes.GET_PROFILE.SUCCESS:
      return { ...state, profile: action.payload };
    case ActionTypes.UPDATE_PASSWORD_SELF.SUCCESS:
      return { ...state, formData: { ...(state.formData), password: "", passwordConfirm: "" } };
    default:
      return state;
  }
}
